import { Injectable } from '@angular/core';
import {
  StaticUtilitiesService,
  iOptionsCustomInput,
} from '@quasar-dynamics/basic-designsystem';

@Injectable({
  providedIn: 'root',
})
export class RecoveryPasswordVariableHandlerClass {
  /**
   * FORM VARIABLES
   */
  newPassData: { new_password: string; recover_token: string } = {
    new_password: '',
    recover_token: '',
  };

  /**
   * OTHER VARIABLES
   */
  isDataCompleted: boolean = false;
  isValidFormat: boolean = true;

  /**
   * INPUT OPTIONS
   */
  passwordOptions: iOptionsCustomInput = {
    label: 'Nueva constraseña',
    placeholder: 'Nueva contraseña',
    type: 'password',
  };

  constructor() {}

  /**
   * VALIDATIONS
   */
  checkIfCompletedAndValidFormat() {
    this.isDataCompleted = StaticUtilitiesService.isCompleteObject(
      this.newPassData
    );
  }
}
