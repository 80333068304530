import { Component, Injector, OnInit } from '@angular/core';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { RecoveryPasswordApiCallsHandlerClass } from './class/recovery-password-api-calls-handler-class';
import { RecoveryPasswordVariableHandlerClass } from './class/recovery-password-variable-handler-class';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss'],
})
export class RecoveryPasswordComponent implements OnInit {
  constructor(
    protected DDISE: DefaultDependenciesInjectorService,
    public apiCallsHandlerClass: RecoveryPasswordApiCallsHandlerClass,
    public variableCallsHandlerClass: RecoveryPasswordVariableHandlerClass
  ) {
    apiCallsHandlerClass = new RecoveryPasswordApiCallsHandlerClass(
      variableCallsHandlerClass,
      DDISE
    );
  }

  ngOnInit(): void {}

  dispatchRecoveryPasswordAction() {
    this.apiCallsHandlerClass.recoveryPassword();
  }
}
