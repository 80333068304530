import { Injectable } from '@angular/core';
import { RecoveryPasswordVariableHandlerClass } from './recovery-password-variable-handler-class';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { iResultHttp } from '@quasar-dynamics/basic-designsystem';

@Injectable({
  providedIn: 'root',
})
export class RecoveryPasswordApiCallsHandlerClass {
  constructor(
    public variableCallsHandlerClass: RecoveryPasswordVariableHandlerClass,
    public DDISE: DefaultDependenciesInjectorService
  ) {}

  /**
   * API RESPONSES
   */
  successRecoveryPasswordResponseHandler(res: iResultHttp) {
    this.DDISE.generalLoader.removeFromLoaderAmount();
  }

  /**
   * CALLS TO API
   */
  recoveryPassword() {}
}
