import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  TokenManagmentService,
  iStaticUtilities,
  DefaultBaseService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { BehaviorSubject } from 'rxjs';

import { iLogin } from 'src/app/Shared/Interfaces/Api/iLogin';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoginService extends DefaultBaseService {
  res: iResultHttp = new iResultHttp();
  constructor(private http: HttpClient) {
    super();
  }

  login(behaviorSubject: BehaviorSubject<any>, loginData: iLogin) {
    this.http.post(this.urlApi + '/api/login_check', loginData).subscribe({
      next: (data: any) => {
        const normalizedTokens = iStaticUtilities.normalizeNames(data);
        this.res = new iResultHttp({
          status: 200,
          ok: true,
          message: '',
          data: normalizedTokens,
        });
        this.setToken(normalizedTokens['token']);
        this.setRefreshToken(normalizedTokens['refreshToken']);
        this.sendNextObservable(behaviorSubject, this.res);
      },
      error: (error: any) => {
        this.res = new iResultHttp({
          status: error.status,
          ok: false,
          message: error.error.message ? error.error.message : '',
        });
        this.sendNextObservable(behaviorSubject, this.res, true, {
          method: this.login,
          args: [behaviorSubject, loginData],
        });
      },
    });
  }
  refreshToken() {
    this.http
      .post(environment.urlApi + '/api/refreshToken', {
        refresh_token: localStorage.getItem('refreshToken'),
      })
      .subscribe({
        next: (data) => {
          let normalizedTokens = iStaticUtilities.normalizeNames(data);
          this.setToken(normalizedTokens['token']);
          this.setRefreshToken(normalizedTokens['refreshToken']);
          TokenManagmentService.completeRefreshToken();
        },
        error: (error) => {
          TokenManagmentService.tokenLost();
        },
      });
  }
}
