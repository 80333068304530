import { Injectable } from '@angular/core';
import {
  StaticUtilitiesService,
  iOptionsCustomInput,
} from '@quasar-dynamics/basic-designsystem';

@Injectable({
  providedIn: 'root',
})
export class SendRecoveryEmailVariableHandlerClass {
  /**
   * FORM VARIABLES
   */
  recoveryData: {username: string} = {
    username: '',
  };

  /**
   * OTHER VARIABLES
   */
  isDataCompleted: boolean = false;
  isValidFormat: boolean = true;

  /**
   * INPUT OPTIONS
   */
  emailOptions: iOptionsCustomInput = {
    label: 'Correo electrónico',
    placeholder: 'Correo electrónico',
    type: 'text',
  };

  constructor() {}

  /**
   * VALIDATIONS
   */
  checkIfCompletedAndValidFormat() {
    this.isDataCompleted = !!this.recoveryData.username
  }

  checkIfIsValidEmailFormat() {
    this.isValidFormat = StaticUtilitiesService.isValidEmailFormat(
      this.recoveryData.username
    );
    !this.isValidFormat
      ? StaticUtilitiesService.showError(
          'Hay errores en alguno de los campos',
          'El formato del correo no es válido'
        )
      : null;
  }
}
