import { RecoveryPasswordComponent } from 'src/app/Pages/access-management/recovery-password/recovery-password.component';
import { SendRecoveryEmailComponent } from 'src/app/Pages/access-management/send-recovery-email/send-recovery-email.component';
import { LoginComponent } from 'src/app/Pages/access-management/login/login.component';
import { PageNotFoundComponent } from 'src/app/Pages/page-not-found/page-not-found.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
  },

  {
    path: 'send-recovery-email',
    component: SendRecoveryEmailComponent,
  },
  {
    path: 'recovery-password',
    component: RecoveryPasswordComponent,
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
    data: { title: 'La página que busca no existe' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
