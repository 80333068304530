import { Injectable } from '@angular/core';
import {
  StaticUtilitiesService,
  iOptionsCustomInput,
  sha256,
} from '@quasar-dynamics/basic-designsystem';
import { iLogin } from 'src/app/Shared/Interfaces/Api/iLogin';

@Injectable({
  providedIn: 'root',
})
export class LoginVariableHandlerClass {
  /**
   * FORM VARIABLES
   */
  loginData: iLogin = {
    username: '',
    password: '',
  };

  /**
   * OTHER VARIABLES
   */
  isDataCompleted: boolean = false;
  isValidFormat: boolean = true;

  /**
   * INPUT OPTIONS
   */
  emailOptions: iOptionsCustomInput = {
    label: 'Correo electrónico',
    placeholder: 'Correo electrónico',
    type: 'text',
  };
  passwordOptions: iOptionsCustomInput = {
    label: 'Contraseña',
    placeholder: 'Contraseña',
    type: 'password',
  };

  constructor() {}

  /**
   * VALIDATIONS
   */
  checkIfCompletedAndValidFormat() {
    this.isDataCompleted = StaticUtilitiesService.isCompleteObject(
      this.loginData
    );
  }

  checkIfIsValidEmailFormat() {
    this.isValidFormat = StaticUtilitiesService.isValidEmailFormat(
      this.loginData.username
    );
    !this.isValidFormat
      ? StaticUtilitiesService.showError(
          'Hay errores en alguno de los campos',
          'El formato del correo no es válido'
        )
      : null;
  }

  /**
   * GETTERS & SETTERS
   */
  getEncryptedLoginData() {
    const encrypteLoginDataObj: iLogin = {
      username: this.loginData.username,
      password: sha256(this.loginData.password)!,
    };
    return encrypteLoginDataObj;
  }
}
