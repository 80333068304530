import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuasarLibraryModule } from '@quasar-dynamics/basic-designsystem';
import { NgSelectModule } from '@ng-select/ng-select';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { CustomDateAdapter } from './Shared/Adapters/CustomDateAdapter';
import localEs from '@angular/common/locales/es';
import { PageNotFoundComponent } from 'src/app/Pages/page-not-found/page-not-found.component';
import { LoginComponent } from 'src/app/Pages/access-management/login/login.component';
import { SendRecoveryEmailComponent } from 'src/app/Pages/access-management/send-recovery-email/send-recovery-email.component';
import { RecoveryPasswordComponent } from 'src/app/Pages/access-management/recovery-password/recovery-password.component';
registerLocaleData(localEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LoginComponent,
    SendRecoveryEmailComponent,
    RecoveryPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    QuasarLibraryModule,
    BrowserAnimationsModule,
    NgSelectModule,
    HttpClientModule,
    FormsModule,
    MatIconModule,
    MatIconModule,
    MatNativeDateModule,
  ],
  providers: [
    CurrencyPipe,
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
