import { Injectable } from '@angular/core';
import { SendRecoveryEmailVariableHandlerClass } from './send-recovery-email-variable-handler-class';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SendRecoveryEmailApiCallsHandlerClass {
  constructor(
    public variableCallsHandlerClass: SendRecoveryEmailVariableHandlerClass,
    public DDISE: DefaultDependenciesInjectorService,
  ) {}

  /**
   * API RESPONSES
   */
  successSendRecoveryEmailResponseHandler(res: iResultHttp) {
    this.DDISE.generalLoader.removeFromLoaderAmount();
  }

  /**
   * CALLS TO API
   */
  sendRecoveryEmail() {}
}
