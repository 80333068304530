<div class="loginGeneralContainer">
  <div class="left">
    <form
      (submit)="variableCallsHandlerClass.isDataCompleted && variableCallsHandlerClass.isValidFormat ? dispatchLoginAction(): null">
      <div class="welcomeMessageContainer">
        <p class="title">Bienvenido</p>
        <p class="subtitle">Introduce tus datos para ingresar</p>
      </div>
      <div class="formElementsContainer">
        <customInput [options]="variableCallsHandlerClass.emailOptions"
          [(ngModel)]="variableCallsHandlerClass.loginData.username" name="username"
          (input)="variableCallsHandlerClass.checkIfCompletedAndValidFormat()"
          (change)="variableCallsHandlerClass.checkIfIsValidEmailFormat()">
        </customInput>
        <customInput [options]="variableCallsHandlerClass.passwordOptions"
          [(ngModel)]="variableCallsHandlerClass.loginData.password" name="password"
          (input)="variableCallsHandlerClass.checkIfCompletedAndValidFormat()">
        </customInput>
        <btn
          [ngClass]="{'disabled': !variableCallsHandlerClass.isDataCompleted || !variableCallsHandlerClass.isValidFormat}">
          <ng-container>
            <span>Iniciar sesión</span>
          </ng-container>
        </btn>
        <a class="recoveryPassword" routerLink="/send-recovery-email" routerAnimationTop>He olvidado mi
          contraseña</a>
      </div>
    </form>
  </div>
  <div class="right"></div>
</div>
