import { Injectable } from '@angular/core';
import { LoginVariableHandlerClass } from './login-variable-handler-class';
import { LoginService } from 'src/app/Services/Api/login.service';
import {
  StaticUtilitiesService,
  iResultHttp,
} from '@quasar-dynamics/basic-designsystem';
import { DefaultDependenciesInjectorService } from 'src/app/Services/Utils/DefaultDependenciesInjector.service';
import { takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginApiCallsHandlerClass {
  constructor(
    public variableCallsHandlerClass: LoginVariableHandlerClass,
    public DDISE: DefaultDependenciesInjectorService,
    private loginSE?: LoginService
  ) {}

  /**
   * API RESPONSES
   */
  successLoginResponseHandler(res: iResultHttp) {
    console.log(res.data);
    this.DDISE.generalLoader.removeFromLoaderAmount();
  }

  /**
   * CALLS TO API
   */
  login() {
    const subject = StaticUtilitiesService.createSubject();
    const behaviorSubject = StaticUtilitiesService.createBehaviorSubject();
    this.DDISE.generalLoader.addToLoaderAmount();
    this.loginSE?.login(
      behaviorSubject,
      this.variableCallsHandlerClass.getEncryptedLoginData()
    );
    behaviorSubject.pipe(takeUntil(subject)).subscribe((res: iResultHttp) => {
      if (res == null) {
        return;
      }
      StaticUtilitiesService.apiResponseHandler(res, subject, [
        {
          method: () => this.successLoginResponseHandler(res),
          error: false,
        },
        {
          method: () => this.DDISE.generalLoader.removeFromLoaderAmount(),
          error: true,
        },
      ]);
    });
  }
}
